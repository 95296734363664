@import url("../../fonts/font.css");
@import url("../mediaScreen/meidaScreen.css");

body {
  font-family: "fontBold";
  user-select: none;
}

.pointerEvent {
  pointer-events: none;
}

.fp-overflow {
  height: 100%;
}

.fp-watermark {
  display: none !important;
}

body {
  background: linear-gradient(to right, #ff47a1 0%, #ff9f4d 100%);
}

#sky {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#shootingstars {
  margin: 0;
  padding: 0;
  width: 150vh;
  height: 100vw;
  position: fixed;
  overflow: hidden;
  transform: translatex(calc(50vw - 50%)) translatey(calc(50vh - 50%))
    rotate(120deg);
}

.wish {
  height: 0.5px;
  // bottom: 500px;
  width: 100px;
  margin: 0;
  opacity: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.534);
  position: absolute;
  background: linear-gradient(-45deg, white, rgba(0, 0, 255, 0));
  filter: drop-shadow(0 0 6px white);
  overflow: hidden;
}

#App {
  overflow: hidden;
}

#fp-nav ul li {
  margin: 50px 10px !important;
}

#fp-nav ul li a.active span {
  height: 33px !important;
  width: 3px !important;
  // margin: -1px 0 0 0px !important;
  border-radius: 15% !important;
  background-color: #fff !important;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.358),
    0 0 1px rgba(255, 255, 255, 0.615), 0 0 1px #ffffff61, 0 0 6px #ffffff3a,
    0 0 0px #0073e6, 0 0 3px #0073e682, 0 0 11px #ffffff91;
}

#fp-nav ul li a {
  display: inline;
  width: 20px !important;
  height: 33px !important;
  margin-top: 5px !important;
}
#fp-nav ul li a span {
  background: transparent !important;
  // box-shadow: 0 0 1px rgba(255, 255, 255, 0.358),
  //   0 0 1px rgba(255, 255, 255, 0.615), 0 0 1px #ffffff61, 0 0 6px #ffffff3a,
  //   0 0 0px #0073e6, 0 0 3px #0073e682, 0 0 11px #ffffff91;
  border-radius: 0 !important;
  background-color: #ffffff58 !important;

  // border: 1px solid rgb(255, 255, 255) !important;
  height: 33px !important;
  width: 3px !important;
  // border-radius: 100% !important;
  margin: 0 !important;

}

#fp-nav ul li:hover a span{
  // margin: 0 !important;
}

.fp-slidesNav ul li:hover a.active span  {
  height: 9px !important;
  width: 5px !important;
  border-radius: 30% !important;

}

.fp-sr-only {
  color: #fff !important;
  background-color: #fff !important;
}

.fp-arrow {
  display: none !important;
}

.styles-module_dark__xNqje {
  background-color: #99999900 !important;
  font-family: fontRegular;
  // backdrop-filter: blur(10px) !important;
}
.styles-module_tooltip__mnnfp {
  backdrop-filter: blur(10px) !important;
  padding: 0 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  position: absolute !important;
  z-index: 99;
}

.styles-module_arrow__K0L3T {
  display: none;
}

.scrollbar-div::-webkit-scrollbar {
  display: none;
}

.scrollbar-div {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


