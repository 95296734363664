.App {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: linear-gradient(129.96deg, #17201e 10.43%, #383a7a70 92.78%),
    radial-gradient(100% 246.94% at 100% 0%, #ffffff 0%, #6f6e93dd 100%),
    linear-gradient(121.18deg, #2ab7d7 0.45%, #3a000010 100%),
    linear-gradient(154.03deg, #040404 0%, #261d13 74.04%),
    linear-gradient(341.1deg, #b25bba 7.52%, #3822c6c7 77.98%),
    linear-gradient(222.34deg, #1b49beb6 12.99%, #195bbce0 87.21%),
    linear-gradient(150.76deg, #a2ac60 15.35%, #66646c 89.57%);
  background-blend-mode: overlay, color-burn, screen, overlay, difference,
    difference, normal; */
  background: linear-gradient(328.78deg, #030086 1%, #bd6177 100.36%),
    linear-gradient(301.28deg, #209b4a 20%, #0a2848 100%),
    radial-gradient(100% 138.56% at 80% 0%, #d50000 30%, #00ffe0 90%),
    radial-gradient(100% 148.07% at 50% 0%, #d50000 10%, #00ffff 90%);
  background-blend-mode: soft-light, overlay, difference, normal;
  overflow: hidden;
}
