@font-face {
  font-family: "fontBold";
  src: url("./IndianTypeFoundry-Poppins-Black.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  font-display: block;
}

@font-face {
  font-family: "fontRegular";
  src: url("./IndianTypeFoundry-Poppins-Regular\ \(1\).woff") format("woff");
  font-style: normal;
  font-weight: normal;
  font-display: block;
}
